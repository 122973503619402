export default {
  projectContent: {
    title: 'black.webp',
    img: "1.webp",
    video: "blackstar/bs.mp4",
    id: "blackstar-carwash",
  },
  content: {
    logo: 'black.webp',
    src: 'https://www.youtube.com/embed/WY-p0RwHijw?rel=0&egm=0',
    img:'blackStar/1.png'
  },
  mockup: {
    img: 'blackStar/mockup1.png',
    img2:  'blackStar/mockup2.png',
    social: 'instagram.png',
    Link: 'black.png',
    link: 'https://www.instagram.com/blackstarcarwash_tashkent/',
    title: 'Black star',
    descr: 'DETALING & CAR WASH',
  },
  about: {
    title: 'BLACK STAR CAR WASH больше, чем просто автомойка',
    descr: 'С первых дней существования проекта, мы являемся его самым близким партнёром. <b>Создание и ведение страницы в Instagram, организация, проведение акций и конкурсов, создание экслюзивных коммерческих роликов </b> — всё это без сомнений доверили нам.',
    video: [
      'blackstar/1.mp4',
      'blackstar/2.mp4',
      'blackstar/3.mp4',
    ]
  },
  cards: {
    img: [
      'blackStar/cards/1.webp',
      'blackStar/cards/2.webp',
      'blackStar/cards/3.webp',
      'blackStar/cards/4.webp',
      'blackStar/cards/5.webp',
      'blackStar/cards/6.webp',
    ]
  }
};
