export default {
  projectContent: {
    title: 'tib.webp',
    img: "7.webp",
    video: "tibbiyot/tibiyot.mp4",
    id: "tibbiyot",
  },
  content: {
    logo: 'tibbiyot.webp',
    src: "https://www.youtube.com/embed/3J_Cf7gJV_Q?rel=0&egm=0",
    img: "tibbiyot/1.png",
  },
  mockup: {
    img: "tibbiyot/mockup1.png",
    img2: "tibbiyot/mockup2.png",
    social: "instagram.png",
    Link: 'tibbiyot.png',
    link: 'https://www.instagram.com/tibbiyot_dunyosi/',
    title: "TIBBIYOT",
    descr: "DUNYOSI",
  },
  about: {
    title:
      "TIBBIYOT DUNYOSI — <br> для тех, кто ценит своё здоровье",
    descr:
      "Медицинское учреждение, в котором вы можете получить результаты 200 видов анализов с высокой точностью за максимально короткое время, а так же получить консультацию опытных специалистов. <br><br> Мы создаём для проекта <b>коммерческие ролики</b> и <b>занимаемся SMM-продвижением.</b>",
    video: ["tibbiyot/1.mp4", "tibbiyot/2.mp4", "tibbiyot/3.mp4"],
  },
  cards: {
    img: [
      "tibbiyot/cards/1.webp",
      "tibbiyot/cards/2.webp",
      "tibbiyot/cards/3.webp",
      "tibbiyot/cards/4.webp",
      "tibbiyot/cards/5.webp",
      "tibbiyot/cards/6.webp",
    ],
  },
};
