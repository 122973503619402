import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("@/views/Home");
const VideoProduction = () => import("@/views/VideoProduction");
const SmmMarketing = () => import("@/views/SmmMarketing");
const ProjectsPages = () => import("@/views/ProjectsPages");
const Page404 = () => import("@/views/Page404");
import setMetas from "@/seo/index";

const WebDevelopment = () => import("@/views/WebDevelopment");
const CardinarProjects = () => import("@/views/CardinarProjects");
const ytongProjects = () => import("@/views/ytongProjects");
const akfasavdoProjects = () => import("@/views/akfasavdoProjects");
const puzzleProjects = () => import("@/views/puzzleProjects");
const maxdecorProjects = () => import("@/views/maxdecorProjects");
const ShtarsystemProjects = () => import("@/views/ShtarsystemProjects");

const projectList =
  "blackstar-carwash|pit|wella|printuz|kanstik|centrisfit|tibbiyot|bscafe";

const routes = [
  // {
  //   path: '/Centris/',
  //   name: 'home',
  //   component: Home,
  // },
  // {
  //   path: `/Centris/project/:id(${projectList})`,
  //   name: 'project',
  //   component: ProjectsPages,
  // },
  // {
  //   path: "/Centris/smm",
  //   name: "smm",
  //   component: SmmMarketing,
  // },
  // {
  //   path: "/Centris/video-production",
  //   name: "video-production",
  //   component: VideoProduction,
  // },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/video-production",
    name: "video-production",
    component: VideoProduction,
  },
  {
    path: "/smm",
    name: "smm",
    component: SmmMarketing,
  },
  {
    path: "/web-development",
    name: "web-development",
    component: WebDevelopment,
  },
  {
    path: "/cardinar",
    name: "cardinar-projects",
    component: CardinarProjects,
  },
  {
    path: "/shtarsystem",
    name: "shtarsystem-projects",
    component: ShtarsystemProjects,
  },
  {
    path: "/ytong",
    name: "ytong-projects",
    component: ytongProjects,
  },
  {
    path: "/akfasavdo",
    name: "akfasavdo-projects",
    component: akfasavdoProjects,
  },
  {
    path: "/puzzle",
    name: "puzzle-projects",
    component: puzzleProjects,
  },
  {
    path: "/maxdecor",
    name: "maxdecor-projects",
    component: maxdecorProjects,
  },
  {
    path: `/project/:id(${projectList})`,
    name: "project",
    component: ProjectsPages,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: Page404,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth", top: 150 };
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to) => {
  window.scrollTo(0, 0);
  setMetas(to);
});

export default router;
