export default {
  mounted(el, binding){
    const options = {
      rootMargin: '0px',
      threshold: 0.1
    }
    const observer = new IntersectionObserver((entries) => {
      if(entries[0].isIntersecting) {
        binding.value()
      }
    }, options)
    observer.observe(el)
  }
}