<template>
  <div class="navigation">
    <div class="container">
      <div class="wrapper-content">
        <router-link :to="{ name: 'home' }">
          <img class="logo" src="@/assets/images/logo.png" alt="logo" />
        </router-link>
        <div class="navigation-list">
          <router-link class="navigation-list-btn" to="/#about-us"
            >О нас</router-link
          >
          <!-- <button
            class="navigation-list-btn"
            @click="scrollToSection('#projects')"
          >
            Наши проекты
          </button> -->
          <router-link
            class="navigation-list-btn"
            :to="{ name: 'smm' }"
            @click="mobileMenuToggle"
            >СММ</router-link
          >
          <router-link
            class="navigation-list-btn"
            :to="{ name: 'web-development' }"
            >Web разработка</router-link
          >
          <router-link
            class="navigation-list-btn"
            :to="{ name: 'video-production' }"
            >Видео продакшн</router-link
          >

          <router-link class="navigation-list-btn" to="/#contact"
            >Контакты</router-link
          >
        </div>
      </div>
    </div>
    <div class="navigation-list-mobile" :class="{ active: menuOpen }">
      <div class="navigation-list-mobile-list">
        <router-link
          class="navigation-list-btn"
          @click="mobileMenuToggle"
          to="/#about-us"
          >О нас</router-link
        >
        <!-- <button
          class="navigation-list-btn"
          @click="scrollToSection('#projects')"
        >
          Наши проекты
        </button> -->
        <router-link
          class="navigation-list-btn"
          :to="{ name: 'smm' }"
          @click="mobileMenuToggle"
          >СММ</router-link
        >
        <router-link
          class="navigation-list-btn"
          :to="{ name: 'web-development' }"
          @click="mobileMenuToggle"
          >Web разработка</router-link
        >
        <router-link
          class="navigation-list-btn"
          :to="{ name: 'video-production' }"
          @click="mobileMenuToggle"
          >Видео продакшн</router-link
        >

        <router-link
          class="navigation-list-btn"
          @click="mobileMenuToggle"
          to="/#contact"
          >Контакты</router-link
        >
      </div>
    </div>
    <button class="menu-burger" :class="{ close: menuOpen }" @click="openMenu">
      <span class="menu-burger-line"></span>
      <span class="menu-burger-line"></span>
      <span class="menu-burger-line"></span>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      viewportWidth: window.innerWidth,
    };
  },
  methods: {
    openMenu() {
      this.menuOpen = !this.menuOpen;
    },
    scrollTo(sectionId) {
      if (this.menuOpen) {
        this.menuOpen = false;
      }
      const section = document.querySelector(sectionId);
      const scrolDistance = section.offsetTop - 50;
      let distance = window.scrollY;
      const scroll = () => {
        if (scrolDistance > distance + 0.5 || scrolDistance < distance - 0.5) {
          distance -= (distance - scrolDistance) / 15;
          window.scrollTo(0, distance);
          this.timer = setTimeout(() => {
            scroll();
          }, 5);
        } else {
          window.scrollTo(0, scrolDistance);
          stop();
        }
      };
      const stop = () => {
        clearTimeout(this.timer);
        window.removeEventListener("wheel", stop);
      };
      stop();
      window.addEventListener("wheel", stop);
      scroll();
    },
    scrollToSection(sectionId) {
      if (this.$route.name != "home") {
        this.$router.push("/").then(() => {
          setTimeout(() => {
            this.scrollTo(sectionId);
          }, 300);
        });
      } else {
        this.scrollTo(sectionId);
      }
    },
    mobileMenuToggle() {
      this.menuOpen = !this.menuOpen;
    },
  },
  computed: {},

  mounted() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/grid/grid";
.navigation {
  width: 100%;
  background: #2e2b2b;
  height: 70px;
  user-select: none;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  @media (max-width: 768px) {
    @include adaptivHeightmd(80, 50);
  }
  .wrapper-content {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    .logo {
      width: 100%;
      @include adaptivWidthLg(121, 110);
      display: block;
      & img {
        object-fit: cover;
      }
      @media (max-width: 768px) {
        @include adaptivWidthmd(160, 90);
      }
    }
  }
  &-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    @media (max-width: 768px) {
      display: none;
    }
    &-btn {
      background: transparent;
      color: #fff;
      border: none;
      @include adaptiv-fontlg(15, 12);
      @include adaptiv-lineHeightlg(20, 14);
      @include adaptivMarginlg(35, 35, 0, 0, 15, 15, 0, 0);
      font-family: "Helvetica-bold";
      font-weight: normal;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      // letter-spacing: 1px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        border-bottom: 1px solid #fff;
        max-width: max-content;
        @media (max-width: 768px) {
          border-bottom: none;
        }
      }
    }
  }

  .navigation-list-mobile {
    display: none;
    @media (max-width: 768px) {
      z-index: 10;
      position: relative;
      &.active {
        display: block;
        @include adaptivWidthmd(768, 320);
        width: 100%;
        height: 100vh;
        background: #19191b;
        position: absolute;
        right: 0;
        top: 100%;
        transform: translate(0%, 0%);
      }
      &-list {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .navigation-list-btn {
      width: 100%;
      padding-top: 18px;
      padding-bottom: 18px;
      @include adaptiv-fontmd(20, 14);
      @include adaptiv-lineHeightlg(20, 14);
      letter-spacing: 2px;
      color: #fff;
      &:hover {
        background: #7338d8;
        max-width: 100%;
      }
    }
  }
  .menu-burger {
    display: none;
    position: absolute;
    top: 30%;
    right: 10%;
    transform: translate(50%, -50%);
    width: 30px;
    height: 50px;
    background: transparent;
    overflow: hidden;
    @media (max-width: 768px) {
      display: block;
      &-line {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        &:first-child {
          margin-top: 20px;
          height: 1px;
          display: flex;
          background: #fff;
          position: absolute;
          left: 0;
          transform: rotate(0);
        }
        &:nth-child(2) {
          margin-top: 10px;
          height: 1px;
          display: flex;
          background: #fff;
          position: absolute;
          left: 0;
          transform: rotate(0);
        }
        &:last-of-type {
          margin-bottom: 0;
          height: 1px;
          display: flex;
          background: #fff;
          position: absolute;
          left: 0;
          transform: rotate(0);
        }
      }
      &.open {
        display: block;
      }
      &.close {
        .menu-burger-line {
          &:first-child {
            display: none;
          }
          &:nth-child(2) {
            transform: rotate(40deg);
            width: 30px;
          }
          &:last-of-type {
            transform: rotate(-40deg);
            margin-top: 10px;
            width: 30px;
          }
        }
      }
    }
  }
}
</style>
