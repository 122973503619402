export default {
    projectContent: {
      title: 'bs.webp',
      img: "8.webp",
      video: "bscafe/bscafe.mp4",
      id: "bscafe",
    },
    content: {
      logo: 'bs.webp',
      src: 'https://www.youtube.com/embed/FzgKaMlI-ms?rel=0&egm=0',
      img:'bscafe/1.png'
    },
    mockup: {
      img: 'bscafe/mockup1.png',
      img2:  'bscafe/mockup2.png',
      social: 'instagram.png',
      Link: 'bs.png',
      link: 'https://www.instagram.com/bscafe.tashkent/',
      title: 'BS cafe',
      descr: '',
    },
    about: {
      title: 'BS CAFE — ваш <br>гастрономический рай',
      descr: 'Место, где шедеврально готовят абсолютно каждое блюдо европейской и паназиатской кухни. <br><br>С нашей стороны были разработаны: <b>логотип, меню, упаковка.</b> Так же, <b>ведётся SMM-продвижение.</b>',
      video: [
        'bscafe/1.mp4',
        'bscafe/2.mp4',
        'bscafe/3.mp4',
      ]
    },
    cards: {
      img: [
        'bscafe/cards/1.webp',
        'bscafe/cards/2.webp',
        'bscafe/cards/3.webp',
        'bscafe/cards/4.webp',
        'bscafe/cards/5.webp',
        'bscafe/cards/6.webp',
      ]
    }
  };
  