export default {
  projectContent: {
    title: '8.webp',
    img: "6.webp",
    video: "centris/fit.mp4",
    id: "centrisfit",
  },
  content: {
   logo: 'fit.webp',
    src: "https://www.youtube.com/embed/0UmjBRYnxkM?rel=0&egm=0",
    img: "centris/1.png",
  },
  mockup: {
    img: "centris/mockup1.png",
    img2: "centris/mockup2.png",
    social: "instagram.png",
    Link: 'fit.png',
    link:'https://www.instagram.com/centris.fit/',
    title: "CENTRIS",
    descr: "FIT&SPA",
  },
  about: {
    title:
      "СENTRIS FIT & SPA  фитнес комплекс, воплощающий в реальность мечты об идеальном теле",
    descr:
      "Партнёр, который доверил нам <b>ведение своей страницы в Instagram</b> с самого начала существования проекта. Мы помогли в создании брендинга и создаём для партнёра оригинальные <b>коммерческие ролики</b>, воплощая идеи в реальность.",
    video: ["centris/1.mp4", "centris/2.mp4", "centris/3.mp4"],
  },
  cards: {
    img: [
      "centris/cards/1.webp",
      "centris/cards/2.webp",
      "centris/cards/3.webp",
      "centris/cards/4.webp",
      "centris/cards/5.webp",
      "centris/cards/6.webp",
    ],
  },
};
