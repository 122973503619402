export default {
  projectContent: {
    title: 'kanstik2.webp',
    img: "5.webp",
    video: "kanstik/kanstik.mp4",
    id: "kanstik",
  },
  content: {
    logo: 'kanstik.webp',
    src: "https://www.youtube.com/embed/bn1L2BNquWE?rel=0&egm=0",
    img: "kanstik/1.png",
  },
  mockup: {
    img: "kanstik/mockup1.png",
    img2: "kanstik/mockup2.png",
    social: "instagram.png",
    Link: 'kanstik.png',
    link:'https://www.instagram.com/kanstik/',
    title: "КАНСТИК",
    descr: "",
  },
  about: {
    title:
      "KANSTIK — товары для офиса и детского творчества",
    descr:
      "Сегодня нет ни одного человека, который не слышал бы это название. А мы помогли в создании <b>брендинга, брендбука.</b> Принимали активное участие в организации интерьера торговых точек и открытии магазинов. С нашей стороны осуществляется поддержка в разработке и <b> проведении акций и SMM-продвижение.</b>",
    video: ["kanstik/1.mp4", "kanstik/3.mp4", "kanstik/2.mp4"],
  },
  cards: {
    img: [
      "kanstik/cards/1.webp",
      "kanstik/cards/2.webp",
      "kanstik/cards/3.webp",
      "kanstik/cards/4.webp",
      "kanstik/cards/5.webp",
      "kanstik/cards/6.webp",
    ],
  },
};
