const metas = {
  home: {
    title: "CENTRIS MEDIA - SMM агентство в Ташкенте | Продвижение в соцсетях",
    description:
      "Мы команда, SMM агенство в Ташкенте, которая знает как продвинуть ВАШ БИЗНЕС в социальных сетях (Facebook + Instagram). Разрабатываем креативные идеи и уникальную концепцию. Наши услуги: SMM продвижение / Разработка Брендбука / Брендинга / Видеопродакшн / Реклама / Логотипы / Разработка дизайна в Ташкенте",
    url: "https://centrismedia.uz/",
    img: "",
    keywords:
      "СММ агентство в Ташкенте, разработка брендинга в Ташкенте, продвижение в соцсетях",
  },
  "video-production": {
    title: "ПРОМО РОЛИКИ | Услуги ВИДЕОПРОДАКШН в Ташкенте | CENTRIS MEDIA",
    description:
      "Поможем вам выйти на новый уровень продаж. Мы предлагаем креативные решения для вашего бизнеса. Создаём информационные и рекламные видеоролики. Берёмся за продакшн, воплощая ваши идеи и сценарии или создаём под ключ с нуля",
    url: "https://centrismedia.uz/video-production",
    img: "",
    keywords:
      "видеопродакшн, video production в Ташкенте, видеопродакшн в Ташкенте, Создание видеоконтента, создание видеороликов",
  },
  smm: {
    title: "SMM | Бесплатный анализ страницы | CENTRIS MEDIA",
    description:
      "Стратегии продвижения • Ведение социальных сетей • Таргетированная реклама • Ситуативный контент • Комьюнити-менеджмент • Копирайт отчётность",
    url: "https://centrismedia.uz/smm",
    img: "",
    keywords:
      "smm в Ташкенте, видеопродакшн в Ташкенте, услуги маркетинга, бесплатный анализ",
  },
  "web-development": {
    title: "WEB | Разработка сайтов | CENTRIS MEDIA",
    description:
      "Мы создаем эффективный инструмент, который поможет вам привлечь новых клиентов, удержать текущих и развить ваш бизнес. Наше маркетинговое агентство предлагает полный спектр услуг",
    url: "https://centrismedia.uz/web-development",
    img: "",
    keywords:
      "разработка сайта в Ташкенте, веб-студия в Ташкенте, web разработка",
  },
  "cardinar-projects": {
    title: "WEB портфолио | cardinar | CENTRIS MEDIA",
    description: "Портфолио работ отдела web-разработки CENTRIS MEDIA",
    url: "https://centrismedia.uz/cardinar",
    img: "",
    keywords: "WEB портфолио, cardinar WEB портфолио",
  },

  "shtarsystem-projects": {
    title: "WEB портфолио | shtarsystem | CENTRIS MEDIA",
    description: "Портфолио работ отдела web-разработки CENTRIS MEDIA",
    url: "https://centrismedia.uz/shtarsystem",
    img: "",
    keywords: "WEB портфолио, shtarsystem WEB портфолио",
  },
  "ytong-projects": {
    title: "WEB портфолио | ytong | CENTRIS MEDIA",
    description: "Портфолио работ отдела web-разработки CENTRIS MEDIA",
    url: "https://centrismedia.uz/ytong",
    img: "",
    keywords: "WEB портфолио, ytong WEB портфолио",
  },
  "akfasavdo-projects": {
    title: "WEB портфолио | akfasavdo | CENTRIS MEDIA",
    description: "Портфолио работ отдела web-разработки CENTRIS MEDIA",
    url: "https://centrismedia.uz/akfasavdo",
    img: "",
    keywords: "WEB портфолио, akfasavdo WEB портфолио",
  },
  "puzzle-projects": {
    title: "WEB портфолио | puzzle | CENTRIS MEDIA",
    description: "Портфолио работ отдела web-разработки CENTRIS MEDIA",
    url: "https://centrismedia.uz/puzzle",
    img: "",
    keywords: "WEB портфолио, puzzle WEB портфолио",
  },
  "maxdecor-projects": {
    title: "WEB портфолио | maxdecor | CENTRIS MEDIA",
    description: "Портфолио работ отдела web-разработки CENTRIS MEDIA",
    url: "https://centrismedia.uz/maxdecor",
    img: "",
    keywords: "WEB портфолио, maxdecor WEB портфолио",
  },
};

function setMetas(to) {
  if (metas[to.name]) {
    let meta;
    meta = metas[to.name];
    document.title = meta.title;
    const metasTag = [...document.getElementsByTagName("meta")];
    metasTag.forEach((element) => {
      if (element.getAttribute("name") == "description") {
        element.setAttribute("content", meta.description);
      }
      if (element.getAttribute("name") == "keywords") {
        element.setAttribute("content", meta.keywords);
      }
      if (element.getAttribute("itemprop") == "description") {
        element.setAttribute("content", meta.description);
      }
      if (element.getAttribute("itemprop") == "url") {
        element.setAttribute("content", meta.url);
      }
      if (element.getAttribute("itemprop") == "name") {
        element.setAttribute("content", meta.title);
      }
      if (element.getAttribute("property") == "og:title") {
        element.setAttribute("content", meta.title);
      }
      if (element.getAttribute("property") == "og:description") {
        element.setAttribute("content", meta.description);
      }
      if (element.getAttribute("property") == "og:url") {
        element.setAttribute("content", meta.url);
      }
      if (element.getAttribute("property") == "og:image") {
        element.setAttribute("content", meta.img);
      }
    });

    const linksTag = [...document.getElementsByTagName("link")];
    linksTag.forEach((element) => {
      if (element.getAttribute("rel") == "canonical") {
        element.setAttribute("href", meta.url);
      }
    });
  }
}
export default setMetas;
