<template>
  <CentrisNavigation />
  <router-view></router-view>
</template>

<script>
import CentrisNavigation from "@/components/CentrisNavigation";

export default {
  data() {
    return {
      viewportWidth: window.innerWidth
    }
  },
  components: {
    CentrisNavigation,
  },
  methods: {},
  mounted() {},
};
</script>
