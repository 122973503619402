export default {
  projectContent: {
    title: 'print.webp',
    img: "4.webp",
    video: "print/print.mp4",
    id: "printuz",
  },
  content: {
    logo: 'print.webp',
    src: "https://www.youtube.com/embed/-VJhI_A8dAc?rel=0&egm=0",
    img: "print/1.png",
  },
  mockup: {
    img: "print/mockup1.png",
    img2: "print/mockup2.png",
    social: "instagram.png",
    Link: 'print.png',
    link: 'https://www.instagram.com/print.uz/',
    title: "PRINT.UZ",
    descr: "",
  },
  about: {
    title: "PRINT.UZ — полиграфия широкого спектра, которой доверяют",
    descr:
      "Здесь вы найдёте всё, что необходимо для успешного развития вашего бизнеса — от создания рекламных буклетов до качественных упаковок. <br><br> Мы помогли проекту в создании <b>логотипов, брендинга и осуществляем полную SMM-поддержку.</b>",
    video: ["print/1.mp4", "print/2.mp4", "print/3.mp4"],
  },
  cards: {
    img: [
      "print/cards/1.webp",
      "print/cards/2.webp",
      "print/cards/3.webp",
      "print/cards/4.webp",
      "print/cards/5.webp",
      "print/cards/6.webp",
    ],
  },
};
