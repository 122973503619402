import { createStore } from "vuex";
import projectData from "@/store/modules/projectData";
import form from "@/store/modules/form";

export default createStore({
  state() {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    projectData,
    form
  },
});
