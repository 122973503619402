import axios from "axios";

export default {
  state() {},
  getters: {},
  mutations: {},
  actions: {
    async sendForm(context, formData){
        let response
        // const url = 'http://form/telegram.php'
        const url = '/telegram.php'
        try{
            response = await axios.post(url, formData)
        }
        catch(error){
            response = error.response
        }
        return response
        
    }
  },
};
