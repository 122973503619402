export default {
  projectContent: {
    title: '3.webp',
    img: "3.webp",
    video: "wella/wella.mp4",
    id: "wella",
  },
  content: {
    logo: 'wella.webp',
    src: "https://www.youtube.com/embed/ilc1p-p0Fr4?rel=0&egm=0",
    img: "wella/1.png",
  },
  mockup: {
    img: "wella/mockup1.png",
    img2: "wella/mockup2.png",
    social: "instagram.png",
    Link: 'wella.png',
    link: 'https://www.instagram.com/wellauzbekistan/',
    title: "Wella",
    descr: "",
  },
  about: {
    title:
      "WELLA — ведущая компания в мире, в области косметики и парфюмерии",
    descr:
      "Поставки по всему Узбекистану качественной продукции напрямую от производителя, чтобы гарантировать покупателям подлинность и отменное качество продукции. С нашей стороны оказывается полная поддержка проекта: <b>создание коммерческих роликов, SMM-продвижение.</b>",
    video: ["wella/1.mp4", "wella/2.mp4", "wella/3.mp4"],
  },
  cards: {
    img: [
      "wella/cards/1.webp",
      "wella/cards/2.webp",
      "wella/cards/3.webp",
      "wella/cards/4.webp",
      "wella/cards/5.webp",
      "wella/cards/6.webp",
    ],
  },
};
