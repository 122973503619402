import projectOne from "@/store/modules/project/projectOne";
import projectTwo from "@/store/modules/project/projectTwo";
import projectThree from "@/store/modules/project/projectThree";
import projectFour from "@/store/modules/project/projectFour";
import projectFive from "@/store/modules/project/projectFive";
import projectSix from "@/store/modules/project/projectSix";
import projectSeven from "@/store/modules/project/projectSeven";
import projectEight from "@/store/modules/project/projectEight";

const state = () => {
    return {
      projectData: {
        'blackstar-carwash': projectOne,
        'pit': projectTwo,
        'wella': projectThree,
        'printuz': projectFour,
        'kanstik': projectFive,
        'centrisfit': projectSix,
        'tibbiyot': projectSeven,
        'bscafe': projectEight,
      }
    }
  }
const getters = {
    getPageData: state => (id) =>{
      return state.projectData[id]
    },
    getProjectContent: state => () =>{
      const array = []
      
      Object.keys(state.projectData).forEach(element => {
        array.push(state.projectData[element].projectContent)
      });
      return array
    },

  }
  export default {
    state,
    getters
  }