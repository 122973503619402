export default {
  projectContent: {
    title: "11.webp",
    img: "2.webp",
    video: "pit/pit.mp4",
    id: "pit",
  },
  content: {
    logo: "pit.webp",
    src: "https://www.youtube.com/embed/Twiks9WEa2E?rel=0&egm=0",
    img: "pit/1.png",
  },
  mockup: {
    img: "pit/mockup1.png",
    img2: "pit/mockup2.png",
    social: "instagram.png",
    Link: "pit.png",
    link: "https://www.instagram.com/pit.uz/",
    title: "P. I. T.",
    descr: "",
  },
  about: {
    title:
      "P.I.T. — <br> высококачественные инструменты и оборудование для строителей, электриков и др.",
    descr:
      "Строительное оборудование и инструменты оптом и в розницу в любую точку Республики Узбекистан.  <br><br> Мы гордимся нашим партнёрством и с удовольствием занимаемся <b>SMM-продвижением страницы P.I.T., созданием коммерческих роликов,</b> которые ярко освещают проблему и решение потребителя",
    video: ["pit/1.mp4", "pit/2.mp4", "pit/3.mp4"],
  },
  cards: {
    img: [
      "pit/cards/1.webp",
      "pit/cards/2.webp",
      "pit/cards/3.webp",
      "pit/cards/4.webp",
      "pit/cards/5.webp",
      "pit/cards/6.webp",
    ],
  },
};
